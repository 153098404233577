@import "../vars";
.display1 {
    font-weight: 500;
    font-size: 42px;
    line-height: 114%;
}

.display2,
h2 {
    font-weight: 500;
    font-size: 34px;
    line-height: 120%;
}

.display3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 124%;
}

.display4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 124%;
}

.subtitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 144%;
}

p {
    font-size: 18px;
    line-height: 140%;
    &:not(:last-child) {
        margin-bottom: 18px;
    }
}

ul {
    padding-left: 11px;
    &:not(:last-child) {
        margin-bottom: 18px;
    }
    li {
        line-height: 140%;
        padding-left: 16px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            z-index: 1;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $black;
            left: 0;
            top: 11px;
        }
        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }
}

ol {
    counter-reset: num;
    padding: 0 0 0 5px;
    &:not(:last-child) {
        margin-bottom: 18px;
    }
    &>li {
        line-height: 140%;
        font-feature-settings: 'case' on;
        position: relative;
        padding-left: 18px;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
        &:before {
            content: counter(num) ".";
            counter-increment: num;
            margin-right: 4px;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

@media screen and (max-width: 800px) {
    .display1,
    .display2,
    h2 {
        font-size: 30px;
    }
    .display3 {
        font-size: 20px;
    }
    .display4 {
        font-size: 18px;
    }
    .subtitle {
        font-size: 18px;
    }
    p {
        font-size: 15px;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    ol {
        padding-left: 2px;
        li {
            padding-left: 15px;
        }
    }
}
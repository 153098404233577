@import 'vars';
@import 'components/_text.scss';
@import 'components/_buttons.scss';
@import 'components/_inputs.scss';
@import 'components/_fonts.scss';
@import 'components/loader';
.ui-kit {
    max-width: 100%;
    width: 1200px;
    padding: 30px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 50px;
    &__group {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px
    }
    &__buttons {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;
    }
    &__inputs {
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 15px));
        gap: 30px;
        .file-input {
            grid-column: 1/3;
            max-width: 500px;
            margin-bottom: 30px;
        }
    }
}

[tooltip] {
    position: relative;
    &:hover {
        &::after,
        &::before {
            opacity: 1;
            pointer-events: unset;
        }
    }
    &::before,
    &::after {
        position: absolute;
        background-color: #fff;
        opacity: 0;
        pointer-events: none;
        transition: all .225s;
        left: 50%;
    }
    &:before {
        content: attr(tooltip);
        z-index: 3;
        width: 278px;
        padding: 16px;
        box-shadow: 0px 4px 28px rgba(9, 66, 117, 0.15);
        border-radius: 12px;
        bottom: 100%;
        transform: translateX(-50%);
        text-align: center;
        font-size: 14px;
        line-height: 140%;
        margin-bottom: 15px;
        opacity: 0;
        pointer-events: none;
        transition: all .225s;
    }
    &:after {
        content: '';
        width: 23px;
        height: 23px;
        bottom: 100%;
        transform: rotate(45deg) translateX(-50%);
        z-index: 4;
    }
}

.scroll-up {
    z-index: 22;
    position: fixed;
    right: 40px;
    bottom: 40px;
    width: 42px;
    height: 42px;
    transition: all .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $primary;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    &:hover {
        background-color: $primaryHover;
    }
    @media screen and (max-width: 800px) {
        right: 12px;
        bottom: 20px;
        width: 32px;
        height: 32px;
        svg {
            width: 14px;
            height: auto;
        }
    }
    &--active {
        visibility: visible;
        opacity: 1;
    }
}

.pagination {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__more {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        font-feature-settings: 'case' on;
        svg {
            margin-right: 12px;
        }
    }
    &__wrap {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__item {
        margin: 0 6px;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 14px;
        &--active {
            color: $primaryHover;
            pointer-events: none;
            background-color: $gray2;
        }
    }
    &__btn {
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        &--prev {
            margin-right: 14px;
        }
        &--next {
            margin-left: 14px;
        }
    }
}

.tabs {
    &-content {
        display: none;
        &--active {
            display: block;
        }
    }
}

@media screen and (max-width: 800px) {
    .pagination {
        display: block;
        margin-top: 32px;
        &__more {
            justify-content: center;
            margin: 0 auto 32px;
            font-size: 14px;
            svg {
                margin-right: 8px;
                width: 18px;
                height: auto;
            }
        }
    }
}
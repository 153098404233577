@import "../vars.scss";
.catalog-card {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background: #FFFFFF;
    position: relative;
    &:hover {
        color: inherit;
    }
    &:before {
        content: '';
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        border: 1px solid $gray3;
        border-radius: 12px;
        left: 0;
        top: 0;
    }
    &:hover {
        box-shadow: 0px 4px 28px rgba(9, 66, 117, 0.15);
        &::before {
            opacity: 0;
        }
    }
    &__top {
        padding: 24px 22px 12px;
        display: flex;
        justify-content: space-between;
        line-height: 140%;
        font-weight: 500;
        svg {
            transform: translateY(3px);
        }
    }
    &__img {
        width: 100%;
        height: 130px;
        margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.catalog-card2 {
    display: flex;
    padding: 32px;
    border-radius: 12px;
    border: 1px solid $gray3;
    &__img {
        width: 160px;
        flex-shrink: 0;
        margin-left: 16px;
        img {
            display: block;
            width: 100%;
        }
    }
    &__desc {
        font-size: 16px;
        line-height: 140%;
        font-feature-settings: 'case' on;
        margin-top: 12px;
    }
    &__link {
        display: flex;
        align-items: center;
        margin-top: 48px;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        svg {
            margin-left: 8px;
        }
    }
}

.done-card {
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid $gray3;
    padding: 32px;
    display: flex;
    flex-direction: column;
    &__img {
        height: 80px;
        display: flex;
        align-items: center;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__desc {
        font-size: 14px;
        line-height: 140%;
        color: $gray;
        margin-top: 8px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    &__items {
        margin-top: 24px;
        padding-top: 24px;
        border-top: 1px solid $gray3;
    }
    &__item {
        font-size: 16px;
        font-feature-settings: 'case' on;
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }
    &__title {
        line-height: 140%;
        color: $gray;
    }
    &__desc2 {
        line-height: 140%;
        margin-top: 4px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
    &__nav {
        padding-top: 32px;
        margin-top: auto;
    }
    &__btn {
        font-weight: 500;
        font-size: 14px;
        width: 100%;
        height: 40px;
        border-radius: 4px;
    }
    &__link {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        margin-top: 12px;
        display: block;
        text-align: center;
    }
}

.product-card {
    border-radius: 12px;
    border: 1px solid $gray3;
    padding: 24px;
    display: flex;
    &--all-sizes {
        .product-card {
            &__size {
                &:nth-of-type(n + 5) {
                    display: none;
                }
                &:nth-last-of-type(1) {
                    display: block !important;
                }
            }
            &__dots {
                display: flex;
            }
            &__sizes-all {
                display: flex;
            }
        }
    }
    &--show-sizes {
        .product-card {
            &__size {
                display: block !important;
            }
            &__dots {
                display: none;
            }
            &__sizes-all {
                font-size: 0;
                &:before {
                    content: 'скрыть';
                    font-size: 14px;
                }
            }
        }
    }
    &__left {
        flex-shrink: 0;
        width: 235px;
    }
    &__center {
        flex-grow: 1;
        margin: 0 35px;
    }
    &__right {
        width: 146px;
        flex-shrink: 0;
    }
    &__img {
        display: block;
        img {
            width: 100%;
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__subtitle {
        color: $gray;
        display: block;
    }
    &__sizes-title,
    &__subtitle,
    &__item {
        font-size: 16px;
        line-height: 140%;
        font-feature-settings: 'case' on;
    }
    &__title {
        display: block;
        margin-top: 20px;
    }
    &__items {
        margin-top: 16px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
    }
    &__sizes {
        margin-top: 16px;
        width: calc(100% + 180px);
        &-title {
            color: $gray;
        }
        &-wrap {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
        &-all {
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
            color: $gray;
            margin: 8px 0 0 0;
            height: 28px;
            align-items: center;
            display: none;
            transition: all 0s;
            &:hover {
                color: $primaryHover;
            }
        }
    }
    &__size {
        margin: 8px 8px 0 0;
        position: relative;
        &:nth-last-of-type(1) {
            margin-right: 16px;
        }
        input {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0;
            &:checked+label {
                color: $primaryHover;
                border-color: $primaryHover;
            }
            &:hover {
                cursor: pointer;
                &+label {
                    border-color: $primaryHover;
                }
            }
        }
        label {
            border: 1px solid #ADB4C0;
            border-radius: 4px;
            height: 28px;
            padding: 0 9px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: $gray;
        }
    }
    &__dots {
        border: 1px solid #ADB4C0;
        border-radius: 4px;
        height: 28px;
        padding: 0 9px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: $gray;
        margin: 8px 8px 0 0;
        display: none;
    }
    &__has {
        font-size: 14px;
        line-height: 140%;
        margin-top: 4px;
        display: flex;
        align-items: center;
        i {
            background-color: $green;
            width: 6px;
            height: 6px;
            display: block;
            border-radius: 50%;
            margin-right: 4px;
            flex-shrink: 0;
        }
        &--warning {
            i {
                background-color: #FC9E41;
            }
        }
    }
    &__btn {
        margin-top: 32px;
        width: 100%;
        height: 40px;
        font-size: 14px;
    }
}

.catalog-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 32px;
    border: 1px solid $gray3;
    margin-top: -1px;
    &__props {
        display: grid;
        grid-template-columns: 1fr 1fr 1.2fr 1fr;
        gap: 10px;
        width: 546px;
        &-item {
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 140%;
            font-feature-settings: 'case' on;
        }
    }
    &__has {
        font-size: 14px;
        line-height: 140%;
        margin-top: 4px;
        display: flex;
        align-items: center;
        i {
            background-color: $green;
            width: 6px;
            height: 6px;
            display: block;
            border-radius: 50%;
            margin-right: 4px;
            flex-shrink: 0;
        }
        &--warning {
            i {
                background-color: #FC9E41;
            }
        }
    }
    &__info {
        width: 146px;
        flex-shrink: 0;
    }
    &__btn {
        width: 146px;
        height: 40px;
        font-size: 14px;
        margin-top: 14px;
    }
}

.search-card {
    border-top: 1px solid $gray3;
    border-bottom: 1px solid $gray3;
    padding: 20px 0;
    display: flex;
    align-items: center;
    &:not(:first-child) {
        margin-top: -1px;
    }
    &__img {
        width: 100px;
        flex-shrink: 0;
        margin-right: 40px;
        img {
            display: block;
            width: 100%;
        }
    }
    &__title {
        margin-right: 20px;
    }
    &__title,
    &__price {
        font-weight: 500;
        line-height: 140%;
        font-feature-settings: 'case' on;
    }
    &__info {
        display: flex;
        align-items: center;
        margin-left: auto;
    }
    &__has {
        font-size: 14px;
        line-height: 140%;
        margin-top: 4px;
        display: flex;
        align-items: center;
        i {
            background-color: $green;
            width: 6px;
            height: 6px;
            display: block;
            border-radius: 50%;
            margin-right: 4px;
            flex-shrink: 0;
        }
        &--warning {
            i {
                background-color: #FC9E41;
            }
        }
    }
    &__btn {
        margin-left: 40px;
        width: 96px;
        height: 36px;
        font-size: 14px;
    }
}

.cart-card {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid $gray3;
    position: relative;
    &--delete {
        .cart-card__deleting {
            display: flex;
        }
    }
    &__deleting {
        position: absolute;
        display: none;
        align-items: center;
        height: 100%;
        width: 100%;
        left: 0;
        background-color: #fff;
        z-index: 3;
        padding-left: 80px;
        &-title {
            font-size: 16px;
            line-height: 140%;
            font-feature-settings: 'case' on;
            color: $gray;
            margin-left: 15px;
            max-width: 216px;
        }
        &-btn {
            font-weight: 500;
            font-size: 14px;
            color: $gray;
            margin-left: auto;
            &:hover {
                color: $black;
            }
        }
    }
    &__img {
        width: 120px;
        flex-shrink: 0;
        display: block;
        margin-right: 20px;
        img {
            display: block;
            width: 100%;
        }
    }
    &__price,
    &__title {
        font-weight: 500;
        font-feature-settings: 'case' on;
    }
    &__size {
        margin-top: 12px;
        font-size: 14px;
        line-height: 140%;
        span {
            color: $gray;
        }
    }
    &__action {
        margin-top: 12px;
        display: flex;
        align-items: center;
    }
    &__delete {
        margin-left: 16px;
    }
    &__price {
        padding-left: 24px;
        margin-left: auto;
        white-space: nowrap;
    }
    &__spinner {
        position: relative;
        width: 41px;
        height: 41px;
        .spinner-border {
            width: 100%;
            height: 100%;
            border-color: transparent;
            border-right-color: transparent;
            border-width: 3px;
            aspect-ratio: 1;
            &:before {
                content: '';
                position: absolute;
                z-index: -1;
                padding: 3px;
                inset: 0;
                background: conic-gradient(from 9.73deg at 50% 50%, #FF0016 0deg, rgba(255, 0, 22, 0) 360deg);
                border-radius: 50%;
                -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
            }
        }
    }
    &__sec {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-weight: 500;
        font-size: 16px;
        color: $red;
        font-feature-settings: 'case' on;
    }
}

@media screen and (max-width: 1200px) {
    .catalog-card2 {
        flex-direction: column;
        &__img {
            order: -1;
            width: 100%;
            height: 140px;
            margin: 0 0 16px;
            img {
                height: 100%;
                width: auto;
                display: block;
                margin: 0 auto;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .product-card {
        &__left {
            width: 170px;
        }
        &__items {
            grid-template-columns: 1fr;
        }
    }
    .catalog-row {
        flex-wrap: wrap;
        padding: 32px;
        &__props {
            order: 2;
            width: 100%;
            padding: 0 95px;
            margin-top: 20px;
        }
        &__action {
            display: flex;
            align-items: center;
            width: unset;
        }
        &__btn {
            margin: 0 0 0 20px;
        }
    }
}

@media screen and (max-width: 800px) {
    .catalog-card {
        &__img {
            height: 110px;
        }
    }
    .catalog-card2 {
        padding: 20px 24px;
        &__img {
            height: 120px;
        }
        &__desc {
            font-size: 14px;
        }
        &__link {
            margin-top: 32px;
        }
    }
    .done-card {
        padding: 24px 20px;
        &__img {
            height: 59px;
        }
        &__desc {
            font-size: 12px;
            line-height: 14px;
            margin-top: 8px;
        }
        &__item {
            font-size: 14px;
        }
        &__desc2 {
            -webkit-line-clamp: 5;
        }
    }
    .catalog-row {
        margin-top: 20px;
        border-radius: 12px;
        padding: 24px 20px;
        position: relative;
        &__info {
            width: 100%;
        }
        &__action {
            order: 3;
            margin-top: 28px;
            width: 100%;
            justify-content: space-between;
        }
        &__props {
            margin-top: 16px;
            padding: 0;
            gap: 8px;
            &-item {
                font-size: 14px;
                &--pressure{
                    &::before{
                        content: "Давление ";
                        margin-right: 5px;
                    }
                }
                &--diameter{
                    &::before{
                        content: "Диаметр ";
                        margin-right: 5px;
                    }
                }
            }
        }
        &__price {
            height: 43px;
        }
        &__btn {
            width: 91px;
        }
        &__has {
            position: absolute;
            bottom: 24px;
            left: 20px;
            font-size: 12px;
        }
    }
}

@media screen and (max-width: 700px) {
    .product-card {
        display: block;
        padding: 5px 20px 24px;
        &__left {
            width: 100%;
        }
        &__center {
            margin: 28px 0;
        }
        &__right {
            width: 100%;
            position: relative;
        }
        &__img {
            height: 150px;
            img {
                display: block;
                margin: 0 auto;
                height: 100%;
                width: auto;
            }
        }
        &__subtitle {
            font-size: 14px;
        }
        &__title {
            margin-top: 16px;
        }
        &__items {
            grid-template-columns: 1fr 1fr;
        }
        &__item {
            font-size: 14px;
        }
        &__sizes {
            width: 100%;
            &-title {
                font-size: 14px;
            }
        }
        &__btn {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 91px;
            height: 40px;
            margin: 0;
        }
    }
    .catalog-row {
        &__props {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@media screen and (max-width: 576px) {

    .search-card {
        flex-wrap: wrap;
        &__title {
            order: -1;
            width: 100%;
            font-size: 14px;
            margin-bottom: 8px;
        }
        &__has {
            font-size: 12px;
        }
        &__price {
            font-size: 14px;
        }
        &__btn {
            margin-left: 33px;
        }
        &__action {
            width: calc(100% - 140px);
        }
    }
    .cart-card {
        position: relative;
        &__img {
            width: 80px;
        }
        &__size {
            margin-top: 8px;
            font-size: 14px;
        }
        &__action {
            margin-top: 45px;
        }
        &__price {
            position: absolute;
            bottom: 62px;
            left: 100px;
            margin: 0;
            padding: 0;
        }
        &__deleting {
            padding-left: 0;
            &-title {
                max-width: 180px;
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .search-card {
        &__img {
            width: 70px;
            margin-right: 30px;
        }
        &__action {
            width: calc(100% - 100px);
        }
    }
}

@media screen and (max-width: 350px) {
    .search-card {
        &__btn {
            margin-left: 20px;
        }
    }
}

@import "../vars.scss";
.projects {
    margin-top: 50px;
    &__grid {
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 15px));
        gap: 60px 30px;
    }
    &__btn {
        margin: 48px auto 0;
        font-size: 16px;
        width: 185px;
        height: 46px;
    }
}

.project-card {
    padding: 40px 32px;
    border: 1px solid $gray3;
    border-radius: 20px;
    &__gallery {
        margin-top: 28px;
        .swiper {
            &-slide {
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                }
            }
            &-button {
                &-prev,
                &-next {
                    width: 28px;
                    height: 28px;
                    margin: 0;
                    transform: translateY(-50%);
                }
                &-prev {
                    left: 12px;
                }
                &-next {
                    right: 12px;
                }
            }
        }
        &-big {
            position: relative;
            max-height: 400px;
            .swiper {
                &-wrapper {
                    align-items: stretch;
                }
                &-slide {
                    height: auto;
                    border-radius: 12px;
                }
            }
        }
        &-small {
            margin-top: 4px;
            .swiper {
                &-wrapper {
                    flex-wrap: wrap;
                }
                &-slide {
                    margin-top: 8px;
                    border-radius: 6px;
                    width: 60px;
                    height: 60px;
                    border: 1px solid $gray3;
                }
            }
        }
    }
    &__img {
        display: block;
        img {
            width: 100%;
            display: block;
        }
    }
    &__logo {
        display: block;
        margin-top: 28px;
        height: 80px;
        width: auto;
    }
    &__desc {
        font-size: 14px;
        line-height: 140%;
        color: $gray;
        margin-top: 8px;
    }
    &__text {
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid $gray3;
    }
    &__subtitle {
        font-size: 16px;
        line-height: 140%;
        font-feature-settings: 'case' on;
        color: $gray;
        margin-bottom: 4px;
        &:not(:first-child) {
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 1059px) {
    .projects {
        &__grid {
            grid-template-columns: 100%;
        }
    }
    .project-card {
        &__gallery {
            &-big {
                max-height: unset;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .projects {
        margin-top: 40px;
        &__grid {
            gap: 40px;
        }
    }
    .project-card {
        padding: 24px 20px;
        border-radius: 12px;
        &__gallery {
            margin-top: 20px;
            &-big {
                .swiper {
                    &-slide {
                        border-radius: 8px;
                    }
                }
            }
            &-small {
                .swiper {
                    &-slide {
                        border-radius: 4px;
                    }
                }
            }
            .swiper {
                &-button {
                    &-prev,
                    &-next {
                        width: 22px;
                        height: 22px;
                        svg {
                            width: 6px;
                            height: auto;
                        }
                    }
                    &-prev {
                        left: 8px;
                    }
                    &-next {
                        right: 8px;
                    }
                }
            }
        }
        &__logo {
            height: 60px;
            margin-top: 20px;
        }
        &__desc {
            font-size: 12px;
            line-height: 14px;
        }
        &__subtitle {
            font-size: 14px;
        }
    }
}
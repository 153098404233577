@import "../vars.scss";
.breadcrumbs {
    &__item {
        font-size: 14px;
        line-height: 140%;
        position: relative;
        display: inline;
        &:last-child {
            color: $gray;
        }
        &:not(:first-child) {
            &:before {
                content: '—';
                margin: 0 8px 0 7px;
            }
        }
    }
    &__back {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .breadcrumbs {
        display: flex;
        align-items: center;
        &__back {
            display: flex;
            align-items: center;
            svg {
                margin-right: 8px;
            }
        }
        &__items {
            display: none;
        }
    }
}
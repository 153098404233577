@import 'vars';
@import 'mixins';
.modal-backdrop {
    &.show {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        opacity: 1;
    }
}

@media screen and (min-width: 600px) {
    .modal {
        text-align: center;
        &:before {
            display: inline-block;
            vertical-align: middle;
            content: " ";
            height: 100%;
        }
        &-dialog {
            display: inline-block;
            vertical-align: middle;
            text-align: left;
        }
    }
}

.modal {
    &-dialog {
        max-width: 562px;
        background: #FFFFFF;
        border-radius: 20px;
        pointer-events: unset;
        padding: 40px 40px 60px 40px;
    }
    &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $gray3;
        border: 1px solid $gray2;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin: 0 0 12px auto;
    }
    &__title {
        text-align: center;
    }
    &__desc {
        text-align: center;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        font-feature-settings: 'case' on;
        margin-top: 12px;
    }
    &__form {
        margin-top: 32px;
    }
    &__check {
        margin-top: 20px;
        input {
            width: 16px;
            height: 20px;
            &:checked+label {
                &::before {
                    background-size: 8px;
                }
            }
        }
        label {
            font-size: 14px;
            color: $gray;
            a {
                text-decoration: underline;
            }
            &::before {
                width: 16px;
                height: 16px;
            }
        }
    }
    &__btn {
        margin-top: 32px;
        height: 57px;
        width: 100%;
        font-size: 18px;
    }
    &__file {
        &-title {
            font-size: 14px;
            line-height: 140%;
            font-weight: 500;
            margin-bottom: 8px;
        }
    }
    &__cart {
        margin: 12px 0 28px;
        &-sum {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 20px;
            font-feature-settings: 'case' on;
            font-weight: 500;
            span {
                margin-left: 12px;
            }
        }
    }
    &__icon {
        background: #ECF9DF;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 20px;
    }
}

.thank-modal {
    .modal {
        &-dialog {
            padding: 32px 41px;
        }
        &__title {
            color: $green;
        }
        &__close {
            position: absolute;
            top: 32px;
            right: 32px;
        }
    }
}

.cookies {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(9, 66, 117, 0.15);
    border-radius: 12px;
    width: 700px;
    max-width: calc(100% - 60px);
    z-index: 22;
    padding: 32px 40px;
    display: flex;
    align-items: center;
    &__text {
        font-size: 14px;
        line-height: 140%;
    }
    &__btn {
        margin-left: 53px;
        width: 154px;
        height: 40px;
        font-size: 14px;
        flex-shrink: 0;
    }
}

@media screen and (max-width: 600px) {
    .modal {
        &-dialog {
            max-width: 100%;
            min-height: 100vh;
            border-radius: 0;
            margin: 0;
            padding: 32px 32px 40px 32px;
        }
        &__close {
            width: 24px;
            height: 24px;
            svg {
                width: 10px;
                height: auto;
            }
        }
        &__desc {
            font-size: 14px;
        }
        &__check {
            label {
                font-size: 12px;
            }
        }
        &__btn {
            margin-top: 24px;
            height: 46px;
            font-size: 16px;
        }
        &__icon {
            width: 60px;
            height: 60px;
            svg {
                width: 36px;
                height: auto;
            }
        }
    }
    .thank-modal {
        text-align: center;
        &:before {
            display: inline-block;
            vertical-align: middle;
            content: " ";
            height: 100%;
        }
        .modal {
            &-dialog {
                padding: 24px;
                min-height: unset;
                max-width: calc(100% - 60px);
                margin-left: auto;
                margin-right: auto;
                display: inline-block;
                vertical-align: middle;
                text-align: left;
                border-radius: 12px;
            }
            &__close {
                top: 24px;
                right: 24px;
            }
        }
    }
    .cookies {
        padding: 20px;
        bottom: 20px;
        &__text {
            font-size: 12px;
            line-height: 14px;
        }
        &__btn {
            margin-left: 15px;
            width: 60px;
            font-size: 0;
            position: relative;
            &:before {
                content: 'Ок';
                position: absolute;
                z-index: 1;
                font-size: 14px;
                line-height: 140%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

@media screen and (max-width: 360px) {
    .modal {
        &-dialog {
            padding: 24px 24px 32px 24px;
        }
    }
    .thank-modal {
        .modal {
            &-dialog {
                max-width: calc(100% - 20px);
            }
        }
    }
}

@import "../vars";
.mbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-out .225s;
    padding: 0;
    border-radius: 4px;
    &:focus {
        box-shadow: none;
    }
}

.btn-blue {
    color: #fff;
    background-color: $primary;
    &:hover {
        color: #fff;
        background-color: $primaryHover;
    }
    &:active {
        color: #fff;
        background-color: $primaryActive;
    }
    &:disabled {
        color: #fff;
        background-color: $gray5;
    }
}

.btn-white {
    background: #FFFFFF;
    border-radius: 4px;
    color: $primary;
    font-weight: 500;
    font-feature-settings: 'case' on;
    &:hover {
        background-color: darken(#fff, 10%);
    }
}

.text-link,
a {
    &:hover {
        color: $primaryHover;
    }
}

.text-link {
    color: $primary;
    &:active {
        color: $primaryActive;
    }
}

.swiper {
    &-scrollbar {
        height: 4px;
        background: $gray3;
        overflow: hidden;
        z-index: 1;
        &-drag {
            background: $primary;
        }
    }
    &-button {
        &-prev,
        &-next {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: #fff;
            box-shadow: 0px 4px 28px rgba(9, 66, 117, 0.15);
            border-radius: 50px;
            &:hover {
                background-color: $primaryHover;
                path {
                    stroke: #fff;
                }
            }
        }
        &-disabled {
            opacity: 1 !important;
        }
    }
}
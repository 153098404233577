@import "../vars.scss";
.partners {
    padding: 50px 0 40px;
    &__items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;
    }
    &__map {
        margin-top: 40px;
        height: 400px;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.partners-card {
    border-radius: 20px;
    border: 1px solid $gray3;
    padding: 37px 32px 32px;
    &__icon {
        height: 70px;
        img {
            width: auto;
            height: 100%;
            display: block;
        }
    }
    &__title {
        margin-top: 25px;
    }
    &__co {
        font-size: 16px;
        line-height: 140%;
        font-feature-settings: 'case' on;
        margin-top: 4px;
    }
    &__items {
        margin-top: 20px;
        a {
            &:hover {
                path {
                    stroke: $primaryHover;
                }
            }
        }
    }
    &__item {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 140%;
        font-feature-settings: 'case' on;
        &:not(:last-child) {
            margin-bottom: 12px;
        }
        &-icon {
            width: 18px;
            flex-shrink: 0;
            margin-right: 12px;
        }
    }
    &__site {
        display: flex;
        align-items: center;
        margin-top: 12px;
        font-size: 16px;
        line-height: 140%;
        svg {
            margin-left: 12px;
        }
    }
    &__map {
        font-size: 14px;
        line-height: 140%;
        text-decoration-line: underline;
        color: $gray;
        display: block;
        margin-top: 20px;
    }
}

.become-partner {
    padding: 40px 0;
    &__items {
        margin-top: 32px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;
    }
    &__item {
        &-icon {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $gray2;
        }
        &-desc {
            margin-top: 12px;
            line-height: 140%;
            text-align: center;
            font-feature-settings: 'case' on;
        }
    }
}

.requirement {
    padding: 40px 0;
    .history__items {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.partner-form {
    padding: 40px 0 120px;
    &__inner {
        border-radius: 20px;
        padding: 60px 505px 60px 40px;
        border: 1px solid $gray3;
        position: relative;
    }
    &__img {
        flex-shrink: 0;
        width: 445px;
        margin-left: 19px;
        position: absolute;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);
        img {
            width: 100%;
            display: block;
        }
    }
    &__desc {
        line-height: 140%;
        font-feature-settings: 'case' on;
        margin-top: 8px;
    }
    &__grid {
        margin-top: 32px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        max-width: 540px;
    }
    &__full {
        grid-column: 1/3;
    }
    .form-group {
        margin: 0;
    }
    &__check {
        label {
            &::before {
                width: 16px;
                height: 16px;
                background-size: 8px auto;
            }
        }
    }
    &__btn {
        margin-top: 12px;
        width: 260px;
        height: 57px;
        font-weight: 500;
        font-size: 18px;
    }
}

@media screen and (max-width: 1200px) {
    .become-partner {
        &__items {
            gap: unset;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin: 0;
            width: calc(100% + 20px);
        }
        &__item {
            margin-top: 32px;
            margin-right: 20px;
            width: calc(50% - 20px);
        }
    }
    .requirement {
        .history__items {
            gap: 20px;
        }
    }
    .partner-form {
        &__inner {
            padding: 60px 40px 360px 40px;
        }
        &__img {
            top: unset;
            bottom: 40px;
            right: 50%;
            transform: translateX(50%);
            height: 290px;
            img {
                width: auto;
                height: 100%;
            }
        }
    }
}

@media screen and (max-width: 1150px) {
    .partners {
        &__items {
            grid-template-columns: 1fr 1fr;
            gap: 20px;
        }
        &__map {
            height: 350px;
        }
    }
}

@media screen and (max-width: 800px) {
    .partners {
        padding-top: 40px;
        &__map {
            margin-top: 32px;
            height: 300px;
        }
    }
    .partners-card {
        padding: 29px 20px 24px;
        &__title {
            margin-top: 16px;
        }
        &__co {
            font-size: 14px;
        }
        &__items {
            margin-top: 16px;
        }
        &__item {
            font-size: 14px;
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
        &__site {
            font-size: 14px;
            margin-top: 8px;
        }
        &__map {
            margin-top: 16px;
            font-size: 12px;
            line-height: 14px;
        }
    }
    .become-partner {
        &__items {
            display: block;
            width: 100%;
        }
        &__item {
            width: 100%;
            margin: 28px 0 0 0;
            display: flex;
            align-items: center;
            &-icon {
                width: 60px;
                height: 60px;
                margin: 0;
                flex-shrink: 0;
                img {
                    width: 33px;
                }
            }
            &-desc {
                margin: 0 0 0 12px;
                text-align: left;
            }
        }
    }
    .requirement {
        .history__items {
            gap: 28px;
            grid-template-columns: 1fr;
        }
    }
    .partner-form {
        padding-bottom: 80px;
        &__inner {
            padding: 0;
            border: none;
        }
        &__img {
            position: static;
            transform: unset;
            max-width: calc(100% - 20px);
            height: unset;
            margin: 0 auto;
            img {
                width: 100%;
                height: auto;
            }
        }
        &__desc {
            margin-top: 20px;
        }
        &__grid {
            grid-template-columns: 1fr;
            max-width: 100%;
        }
        &__full {
            grid-column: 1/2;
        }
        &__check {
            label {
                font-size: 12px;
                line-height: 14px;
                min-height: 14px;
            }
        }
        &__btn {
            margin-top: 4px;
            height: 46px;
            font-size: 16px;
            width: 100%;
            max-width: 400px;
        }
    }
}

@media screen and (max-width: 650px) {
    .partners {
        &__items {
            grid-template-columns: 1fr;
        }
    }
}
@import "_header";
@import "_header-catalog";
@import "_header-search";
@import "_footer";
.wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 800px) {
        padding-top: 70px !important;
    }
}

.container {
    max-width: 1270px;
    margin: 0 auto;
    padding: 0 50px;
    @media screen and (max-width: 800px) {
        padding: 0 30px;
    }
    @media screen and (max-width: 360px) {
        padding: 0 10px;
    }
}
@import "../vars.scss";
.contacts {
    padding: 40px 0 120px;
    &__inner {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
    }
    &__items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 60px;
    }
    &__item {
        &-icon {
            width: 32px;
            height: 32px;
            margin-bottom: 20px;
            display: block;
            img {
                width: 100%;
                height: auto;
            }
        }
        &-social,
        &-address,
        &-link {
            font-weight: 500;
            line-height: 140%;
            font-feature-settings: 'case' on;
        }
        &-hint {
            font-size: 14px;
            line-height: 140%;
            color: $gray;
            margin-top: 4px;
        }
        &-social {
            display: flex;
            align-items: center;
            svg {
                margin-right: 12px;
            }
            &:not(:last-child) {
                margin-bottom: 12px;
            }
            &:hover {
                color: $primaryHover;
                .path-hover {
                    fill: $primaryHover;
                }
            }
        }
    }
}

.contacts-req {
    margin-top: 80px;
    padding: 24px 16px;
    border-top: 1px solid $gray3;
    border-bottom: 1px solid $gray3;
    &__show {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &--active {
            .contacts-req__btn {
                svg {
                    transform: rotate(45deg);
                }
            }
        }
    }
    &__hidden {
        padding-top: 12px;
        display: none;
        span {
            display: block;
        }
    }
}

.contacts-form {
    padding: 40px;
    border: 1px solid $gray3;
    border-radius: 20px;
    &__desc {
        line-height: 140%;
        font-feature-settings: 'case' on;
        margin-top: 8px;
    }
    &__grid {
        margin-top: 32px;
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 10px));
        gap: 20px;
        max-width: 540px;
    }
    &__full {
        grid-column: 1/3;
    }
    &__check {
        label {
            &::before {
                width: 16px;
                height: 16px;
                background-size: 8px auto;
            }
        }
        a {
            text-decoration: underline;
        }
    }
    &__btn {
        margin-top: 12px;
        width: 236px;
        height: 57px;
        font-weight: 500;
        font-size: 18px;
    }
    .form-group {
        margin: 0;
    }
}

@media screen and (max-width: 1200px) {
    .contacts {
        &__inner {
            grid-template-columns: 1fr;
            gap: 80px;
        }
    }
    .contacts-form {
        max-width: 490px;
        padding: 0;
        border: none;
    }
}

@media screen and (max-width: 800px) {
    .contacts {
        &__item {
            &-icon {
                width: 28px;
                height: 28px;
                margin-bottom: 16px;
            }
            &-hint {
                font-size: 12px;
            }
            &-social {
                svg {
                    margin-right: 8px;
                }
            }
        }
    }
    .contacts-req {
        padding: 20px 12px;
        &__btn {
            svg {
                width: 20px;
                height: auto;
            }
        }
        &__hidden {
            padding-top: 8px;
        }
    }
    .contacts-form {
        &__desc {
            margin-top: 12px;
        }
        &__grid {
            margin-top: 24px;
        }
        &__btn {
            font-size: 16px;
            margin-top: 4px;
            height: 46px;
            width: 100%;
            max-width: 400px;
        }
    }
}

@media screen and (max-width: 576px) {
    .contacts {
        &__items {
            grid-template-columns: 1fr;
            gap: 40px;
        }
    }
    .contacts-form {
        &__grid {
            grid-template-columns: 1fr;
        }
        &__full {
            grid-column: 1/2;
        }
    }
}
@import "../vars.scss";
@import "../mixins";
.main {
    background-color: $gray2;
    padding: 60px 0;
    &__inner {
        display: flex;
        border-radius: 20px;
        background-color: $primary;
        overflow: hidden;
    }
    &__info {
        padding: 80px 13px 80px 40px;
    }
    &__title {
        color: #fff;
    }
    &__link {
        margin-top: 48px;
        width: 233px;
        height: 53px;
        font-size: 18px;
    }
    &__img {
        height: 100%;
        flex-shrink: 0;
        img {
            height: 100%;
            display: block;
        }
    }
}

.advantages {
    margin-top: 48px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 32px;
    &__title {
        font-weight: 500;
        font-size: 24px;
        line-height: 124%;
    }
    &__desc {
        line-height: 140%;
        margin-top: 8px;
    }
}

.catalog-index {
    background-color: $gray2;
    padding: 60px 0 120px;
    border-bottom: 1px solid $gray3;
    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__link {
        display: flex;
        align-items: stretch;
        font-weight: 500;
        svg {
            margin-left: 16px;
        }
        &--mob {
            display: none;
        }
    }
    &__items {
        display: flex;
        flex-wrap: wrap;
        margin-top: 2px;
        width: calc(100% + 30px);
    }
    .catalog-card {
        width: calc(25% - 30px);
        margin: 30px 30px 0 0;
    }
}

.catalog-consult {
    width: calc(75% - 30px);
    margin: 30px 30px 0 0;
    min-height: 240px;
    padding: 40px 200px 40px 40px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    background-color: $black;
    color: #fff;
    flex-grow: 1;
    &__info {
        position: relative;
        z-index: 2;
    }
    &__img {
        right: 0px;
        bottom: 0;
        width: 447px;
        position: absolute;
        height: 100%;
        img {
            min-height: 100%;
            height: auto;
            width: 100%;
            display: block;
        }
    }
    &__desc {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        margin-top: 8px;
        max-width: 330px;
    }
    &__btn {
        margin-top: 20px;
        width: 205px;
        height: 46px;
        font-size: 16px;
    }
}

.consult {
    padding: 120px 0;
    background-color: #fff;
    &--pb60 {
        padding-bottom: 60px;
    }
    &__inner {
        background-color: $gray2;
        border-radius: 20px;
        border: 1px solid $gray3;
        padding: 64px 370px 28px 40px;
        position: relative;
        overflow: hidden;
        &:before {
            content: '';
            position: absolute;
            z-index: 1;
            width: 498px;
            height: 485px;
            background-image: url(../img/rounds.svg);
            background-repeat: no-repeat;
            background-size: 100% auto;
            right: -77px;
            bottom: -131px;
        }
    }
    &__img {
        position: absolute;
        width: 307px;
        bottom: 0;
        right: 54px;
        z-index: 2;
        img {
            width: 100%;
            display: block;
        }
    }
    &__info {
        position: relative;
        z-index: 2;
    }
    &__desc {
        line-height: 140%;
        font-feature-settings: 'case' on;
        margin-top: 8px;
        max-width: 560px;
    }
    &__nav {
        margin-top: 40px;
        display: flex;
    }
    &__btn {
        width: 277px;
        height: 46px;
        font-weight: 500;
        font-size: 16px;
    }
    &__contacts {
        margin-left: 48px;
        &-wrap {
            display: flex;
            align-items: center;
        }
    }
    &__phone {
        font-weight: 500;
        line-height: 140%;
        font-feature-settings: 'case' on;
        margin-left: 7px;
    }
    &__socials {
        display: flex;
        align-items: center;
        &-item {
            margin-left: 24px;
            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
            img {
                transition: all .225s;
            }
        }
    }
    &__hint {
        font-size: 14px;
        line-height: 140%;
        color: $gray;
        margin-top: 2px;
    }
    &__bottom {
        margin-top: 15px;
        text-align: right;
    }
    &__name,
    &__prof {
        font-weight: 500;
        line-height: 140%;
        font-feature-settings: 'case' on;
    }
    &__prof {
        margin-top: 2px;
        color: $gray;
    }
}

.about-index {
    padding-bottom: 60px;
    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__link {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-feature-settings: 'case' on;
        line-height: 140%;
        svg {
            margin-left: 12px;
        }
    }
    &__wrap {
        display: flex;
        justify-content: space-between;
        margin-top: 32px;
    }
    &__desc {
        max-width: 470px;
    }
    &__docs {
        width: 540px;
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
    }
    &__doc {
        width: 240px;
        flex-shrink: 0;
        img {
            width: 100%;
            display: block;
        }
        &-title {
            font-size: 16px;
            line-height: 140%;
            font-feature-settings: 'case' on;
            margin-top: 8px;
            text-align: center;
        }
    }
}

.advantages-about {
    margin-top: 40px;
    border: 1px solid $gray3;
    padding: 40px 70px;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    &__title {
        font-weight: 500;
        font-size: 24px;
        line-height: 124%;
    }
    &__desc {
        line-height: 140%;
        font-feature-settings: 'case' on;
        margin-top: 8px;
    }
}

.production {
    margin-top: 120px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    &__group {
        width: calc(50% - 15px);
        display: flex;
        flex-direction: column;
    }
    &__items {
        flex-grow: 1;
        margin-top: 32px;
        border-radius: 12px;
        padding: 32px;
        border: 1px solid $gray3;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
    }
    &__subtitle {
        font-weight: 500;
        font-size: 24px;
        line-height: 124%;
    }
    &__desc {
        line-height: 140%;
        margin-top: 8px;
    }
    &__desc2 {
        font-size: 14px;
        line-height: 140%;
        margin-top: 4px;
        color: $gray;
    }
}

.stage {
    padding: 60px 0 120px;
    &__items {
        margin-top: 32px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 30px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            z-index: 1;
            top: 22px;
            left: 0;
            width: calc(75% + 30px);
            height: 1px;
            background-color: $primary;
        }
    }
    &__item {
        &-num {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            position: relative;
            z-index: 2;
            background: #FFFFFF;
            border: 1px solid $primary;
            font-size: 20px;
            color: $primary;
        }
        &-time {
            border-radius: 4px;
            padding: 0 7px;
            height: 30px;
            display: inline-flex;
            align-items: center;
            white-space: nowrap;
            font-size: 14px;
            background-color: $gray2;
            margin-top: 27px;
        }
        &-title {
            font-weight: 500;
            font-size: 20px;
            line-height: 124%;
            margin-top: 19px;
        }
        &-desc {
            font-size: 16px;
            line-height: 140%;
            margin-top: 8px;
            [tooltip] {
                display: inline-block;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
    }
    &__btn {
        width: 179px;
        height: 40px;
        margin-top: 20px;
        font-weight: 500;
        font-size: 14px;
    }
}

.done-projects {
    padding: 120px 0 60px;
    background-color: $gray2;
    border-top: 1px solid $gray3;
    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;
    }
    &__link {
        display: flex;
        align-items: center;
        font-weight: 500;
        svg {
            margin-left: 16px;
        }
        &--mob {
            display: none;
        }
    }
    &__items {
        @media screen and (min-width: 1200px) {
            display: grid;
            grid-template-columns: repeat(3, calc(33.333% - 20px));
            gap: 30px;
        }
    }
    .swiper {
        &-wrapper {
            align-items: stretch;
            @media screen and (min-width: 1200px) {
                transform: unset !important;
            }
        }
        &-slide{
            height: auto;
        }
        &-scrollbar {
            @media screen and (min-width: 1200px) {
                display: none;
            }
        }
    }
    .done-card{
        height: 100%;
    }
}

.distribute {
    background-color: $gray2;
    padding: 60px 0 120px;
    &__inner {
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        border-radius: 20px;
        border: 1px solid $gray3;
    }
    &__info {
        padding: 60px 40px 64px;
        max-width: 630px;
    }
    &__desc {
        line-height: 140%;
        margin-top: 8px;
        font-feature-settings: 'case' on;
    }
    &__nav {
        margin-top: 40px;
        display: flex;
        align-items: center;
    }
    &__btn {
        font-weight: 500;
        font-size: 16px;
        font-feature-settings: 'case' on;
        width: 215px;
        height: 46px;
    }
    &__link {
        margin-left: 32px;
        font-size: 16px;
        font-weight: 500;
        svg {
            margin-right: 12px;
        }
    }
    &__img {
        width: 498px;
        min-height: 100%;
        flex-shrink: 0;
        img {
            width: 100%;
        }
    }
}

@media screen and (max-width: 1200px) {
    .main {
        &__inner {
            display: block;
        }
        &__info {
            padding: 48px 40px;
        }
        &__img {
            width: 100%;
            height: unset;
            max-width: 700px;
            margin: 0 auto;
            img {
                width: 100%;
                height: unset;
            }
        }
    }
    .advantages {
        grid-template-columns: 1fr 1fr;
    }
    .catalog-index {
        &__items {
            margin-top: 12px;
            width: calc(100% + 20px);
        }
        .catalog-card {
            width: calc(33.3333333% - 20px);
            margin: 20px 20px 0 0;
        }
    }
    .catalog-consult {
        margin: 20px 20px 0 0;
    }
    .consult {
        padding: 100px 0;
        &--pb60 {
            padding-bottom: 50px;
        }
        &__inner {
            padding: 60px 40px 28px;
            &::before {
                width: 400px;
                height: 368px;
                right: -54px;
                bottom: -35px;
            }
        }
        &__nav {
            margin-top: 44px;
            display: block;
        }
        &__contacts {
            margin: 30px 0 0 14px;
        }
        &__bottom {
            margin-top: 90px;
            padding-right: 312px;
        }
        &__img {
            right: 0;
        }
    }
    .about-index {
        padding-bottom: 50px;
        &__wrap {
            display: block;
        }
        &__desc {
            max-width: 100%;
        }
        &__docs {
            margin: 40px auto 0;
            width: 520px;
        }
        &__doc {
            width: 250px;
        }
    }
    .advantages-about {
        padding: 40px;
    }
    .production {
        display: block;
        margin-top: 100px;
        &__group {
            width: 100%;
            &:not(:last-child) {
                margin-bottom: 60px;
            }
        }
        &__items {
            gap: 20px;
        }
    }
    .stage {
        padding: 50px 0 100px;
        &__items {
            grid-template-columns: 1fr 1fr;
            gap: 36px 20px;
            &::before {
                display: none;
            }
        }
    }
    .done-projects {
        padding: 100px 0 50px;
        overflow: hidden;
        .swiper {
            &-container {
                position: relative;
                margin-top: 32px;
            }
            &-wrapper {
                align-items: stretch;
            }
            &-slide {
                width: 370px;
                height: auto;
                &:last-child {
                    display: none;
                }
            }
            &-scrollbar {
                bottom: -30px;
            }
        }
        .done-card {
            height: 100%;
        }
    }
    .distribute {
        padding: 50px 0 100px;
        &__info {
            padding: 50px 40px;
            max-width: 100%;
        }
        &__img {
            display: none;
        }
    }
}

@media screen and (max-width: 1000px) {
    .catalog-index {
        .catalog-card {
            width: calc(50% - 20px);
        }
    }
}

@media screen and (max-width: 800px) {
    .main {
        padding: 40px 0;
        &__inner {
            border-radius: 12px;
        }
        &__info {
            padding: 40px 22px 12px;
        }
        &__link {
            height: 40px;
            margin-top: 25px;
            font-size: 14px;
            width: 400px;
            max-width: 100%;
        }
    }
    .advantages {
        margin-top: 32px;
        gap: 28px;
        &__title {
            font-size: 20px;
        }
        &__desc {
            margin-top: 4px;
        }
    }
    .catalog-index {
        padding: 40px 0 80px;
        &__items {
            width: calc(100% + 16px);
        }
        &__link {
            width: 100%;
            margin-top: 32px;
            justify-content: center;
            &--dekstop {
                display: none;
            }
            &--mob {
                display: flex;
            }
            svg {
                margin-left: 12px;
            }
        }
        .catalog-card {
            margin: 16px 16px 0 0;
            width: calc(50% - 16px);
        }
    }
    .catalog-consult {
        padding: 0;
        margin: 60px 16px 0 0;
        &__info {
            padding: 40px 20px 20px;
        }
        &__desc {
            font-size: 15px;
            margin-top: 12px;
        }
        &__btn {
            margin-top: 32px;
            width: 170px;
            height: 40px;
            font-size: 14px;
        }
    }
    .consult {
        padding: 80px 0;
        &--pb60 {
            padding-bottom: 40px;
        }
        &__inner {
            border-radius: 12px;
            padding: 40px 20px 196px;
            &::before {
                width: 388px;
                height: 378px;
                bottom: -102px;
                right: -112px;
            }
        }
        &__desc {
            margin-top: 12px;
        }
        &__nav {
            margin-top: 32px;
            display: flex;
            flex-direction: column;
        }
        &__contacts {
            margin: 12px 0 0;
            display: flex;
            flex-direction: column;
        }
        &__hint {
            order: -1;
            font-size: 12px;
            line-height: 14px;
            margin: 0 0 9px;
        }
        &__phone {
            margin-left: 0;
        }
        &__btn {
            width: 400px;
            max-width: 100%;
            font-size: 14px;
            height: 40px;
        }
        &__bottom {
            margin-top: 40px;
            text-align: left;
            padding-right: 110px;
        }
        &__img {
            width: 240px;
            right: -30px;
        }
    }
    .about-index {
        padding-bottom: 40px;
        &__info {
            position: relative;
            padding-bottom: 53px;
        }
        &__link {
            position: absolute;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 0;
            bottom: 0;
        }
        &__docs {
            width: 500px;
            max-width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
            margin-top: 32px;
        }
        &__doc {
            width: 100%;
            &-title {
                font-size: 14px;
                margin-top: 4px;
            }
        }
        &__wrap {
            margin-top: 28px;
        }
    }
    .advantages-about {
        padding: 32px 20px;
        margin-top: 38px;
        &__title {
            font-size: 20px;
        }
        &__desc {
            margin-top: 4px;
        }
    }
    .production {
        margin-top: 80px;
        &__group {
            &:not(:last-child) {
                margin-bottom: 40px;
            }
        }
        &__items {
            padding: 32px 20px;
            margin-top: 28px;
        }
        &__subtitle {
            font-size: 20px;
        }
        &__desc {
            margin-top: 4px;
        }
    }
    .stage {
        padding: 40px 0 80px;
        &__items {
            grid-template-columns: 1fr;
            gap: 40px;
            &::before {
                width: 1px;
                height: 100%;
                left: 20px;
                top: 0;
                display: block;
            }
        }
        &__item {
            position: relative;
            padding-left: 52px;
            display: flex;
            flex-wrap: wrap;
            &:last-child {
                &:before {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    width: 1px;
                    height: 100%;
                    left: 20px;
                    top: 0;
                    background-color: #fff;
                }
            }
            &-num {
                position: absolute;
                top: 0;
                left: 0;
                width: 40px;
                height: 40px;
                font-size: 18px;
            }
            &-title {
                order: -1;
                margin-top: 9px;
                font-size: 18px;
                width: 100%;
            }
            &-time {
                margin-top: 8px;
                font-size: 12px;
                height: 24px;
            }
            &-desc {
                width: 100%;
                font-size: 14px;
                margin-top: 8px;
                position: relative;
                [tooltip] {
                    position: static;
                    &::before {
                        width: 200px;
                        font-size: 12px;
                        padding: 12px 12px 20px;
                    }
                }
            }
        }
    }
    .done-projects {
        padding: 80px 0 40px;
        &__top {
            margin-bottom: 28px;
        }
        &__link {
            margin-right: 20px;
            &--dekstop {
                display: none;
            }
            &--mob {
                white-space: nowrap;
                display: flex;
            }
            svg {
                margin-left: 12px;
            }
        }
        .swiper {
            &-container {
                margin-top: 28px;
            }
            &-slide {
                width: 270px;
                &:last-child {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            &-scrollbar {
                bottom: -28px;
            }
        }
    }
    .distribute {
        padding: 40px 0 80px;
        &__inner {
            border-radius: 12px;
            flex-direction: column;
        }
        &__img {
            order: -1;
            display: block;
            width: 100%;
            max-width: 400px;
            margin: 0 auto;
            min-height: unset;
        }
        &__info {
            padding: 32px 20px 40px;
        }
        &__desc {
            margin-top: 16px;
        }
        &__nav {
            margin-top: 32px;
            display: block;
        }
        &__btn {
            margin: 0 auto;
            height: 40px;
            width: 100%;
            max-width: 400px;
        }
        &__link {
            width: 100%;
            max-width: 400px;
            margin: 12px auto 0;
            font-size: 14px;
            svg {
                margin-right: 8px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .advantages-about {
        display: block;
        text-align: left;
        br {
            display: none;
        }
        &__item {
            &:not(:last-child) {
                margin-bottom: 28px;
            }
        }
    }
    .catalog-consult {
        &__info {
            padding-bottom: 0;
        }
        &__img {
            position: relative;
            height: unset;
            width: 100%;
            &:before {
                content: '';
                position: absolute;
                z-index: 1;
                background: linear-gradient(180deg, #000B15 0%, rgba(0, 11, 21, 0) 62.67%);
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .advantages {
        grid-template-columns: 1fr;
    }
    .catalog-index {
        &__items {
            width: 100%;
        }
        .catalog-card {
            width: 100%;
            margin-right: 0;
        }
        .catalog-consult {
            margin-right: 0;
        }
    }
    .production {
        &__items {
            gap: 28px;
            grid-template-columns: 1fr;
        }
    }
    .done-projects {
        &__items {
            width: calc(100% + 20px);
            margin-left: -10px;
            padding-left: 10px;
        }
    }
}

@import "../vars.scss";
input,
textarea {
    font-family: inherit;
    &::placeholder {
        color: $gray;
    }
}

.check,
.radio {
    position: relative;
    input {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 2;
        cursor: pointer;
        &:checked+label {
            &::before {
                border-color: $primaryHover;
                background-color: $primaryHover;
                background-image: url(../img/check.svg);
            }
        }
        &:hover+label {
            color: $primaryHover;
            &::before {
                border-color: $primaryHover;
            }
        }
        &:disabled {
            pointer-events: none;
            &+label {
                pointer-events: none;
                &::before {
                    background: #E6E9EC;
                    border: 1px solid #E6E9EC;
                }
            }
        }
    }
    label {
        padding-left: 28px;
        position: relative;
        min-height: 20px;
        font-size: 16px;
        line-height: 140%;
        font-feature-settings: 'case' on;
        cursor: pointer;
        &:before {
            content: '';
            position: absolute;
            z-index: 1;
            border: 1px solid #ADB4C0;
            border-radius: 4px;
            width: 20px;
            height: 20px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: all .100s;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

.radio {
    input {
        &:checked+label {
            &::before {
                background-image: none;
                background-color: transparent;
                border-width: 6px;
            }
        }
    }
    label {
        &::before {
            border-radius: 50%;
        }
    }
}

.custom-select {
    position: relative;
    &--active {
        .custom-select__dropdown {
            opacity: 1;
            pointer-events: unset;
        }
        .custom-select__arrow {
            svg {
                transform: rotate(180deg);
            }
        }
        input {
            pointer-events: none;
        }
    }
    input {
        width: 100%;
        height: 20px;
        padding: 0 14px;
        font-size: 14px;
        cursor: pointer;
        border: none;
        color: $gray;
    }
    &__arrow {
        position: absolute;
        right: 0;
        top: 2px;
        pointer-events: none;
    }
    &__dropdown {
        position: absolute;
        top: 100%;
        margin-top: 9px;
        width: 100%;
        z-index: 20;
        background-color: #fff;
        box-shadow: 0px 4px 28px rgba(9, 66, 117, 0.15);
        border-radius: 8px;
        padding: 12px 14px;
        opacity: 0;
        pointer-events: none;
        transition: all .225s;
    }
    &__item {
        font-size: 14px;
        line-height: 140%;
        transition: all .225s;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
        &:hover {
            color: $primaryHover;
            cursor: pointer;
        }
        &--selected {
            pointer-events: none;
            font-weight: 500;
        }
    }
}

.cartcalc {
    flex-shrink: 0;
    width: 114px;
    height: 40px;
    border: 1px solid $gray3;
    padding: 0 9px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
        background-color: transparent;
        border: none;
        width: calc(100% - 52px);
        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        height: 100%;
        text-align: center;
        border: 1px solid transparent;
        color: $gray;
    }
    button {
        width: 24px;
        height: 24px;
        &:hover {
            path {
                stroke: $primaryHover;
            }
        }
        &:active {
            path {
                stroke: $black;
            }
        }
    }
    .ccalc-disabled {
        pointer-events: none;
        path {
            stroke: $gray;
        }
    }
}

.form-group {
    position: relative;
    margin-bottom: 20px;
    input,
    textarea {
        background: transparent;
        border-radius: 8px;
        width: 100%;
        height: 54px;
        padding: 0 16px;
        font-size: 16px;
        line-height: 140%;
        border: 1px solid #ADB4C0;
        font-feature-settings: 'case' on;
        &:hover {
            border-color: $primaryHover;
        }
        &::placeholder {
            color: $gray;
            font-size: 16px;
        }
        &:disabled {
            background: #F5F5F5;
            border-color: #f5f5f5;
            pointer-events: none;
        }
    }
    label {
        font-size: 14px;
        line-height: 140%;
        font-weight: 500;
        display: block;
        margin-bottom: 8px;
    }
    textarea {
        min-height: 92px;
        resize: vertical;
        padding-top: 12px;
        padding-bottom: 12px;
        resize: none;
    }
    &--required {
        label {
            &:after {
                content: '*';
                color: $red;
                margin-left: 3px;
            }
        }
    }
    &--error {
        .form-group__error-text {
            display: flex;
        }
    }
    &__error-text {
        display: none;
        align-items: center;
        position: absolute;
        top: 100%;
        margin-top: 9px;
        color: $gray;
        font-size: 12px;
        line-height: 120%;
        svg {
            margin-right: 4px;
        }
    }
}

.file-input {
    position: relative;
    &__drop-area {
        position: relative;
        height: 132px;
    }
    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 2;
        &:hover+label {
            border-color: $primaryHover;
        }
        &:active,
        &.is-active {
            &+label {
                border-color: $primaryHover;
            }
        }
    }
    label {
        height: 100%;
        width: 100%;
        border: 1px dashed $gray;
        background-color: $gray2;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        padding: 0 20px;
        font-size: 14px;
        line-height: 140%;
        transition: all .225s;
        color: $gray;
        i {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $gray3;
            margin-bottom: 12px;
        }
        span {
            span {
                text-decoration: underline;
            }
        }
    }
    &--uploading {
        .file-input__upload--uploading {
            display: flex;
        }
    }
    &--uploaded {
        .file-input__upload--uploaded {
            display: flex;
        }
    }
    &--error {
        .file-input__upload--uploaded,
        .form-group__error-text {
            display: flex;
        }
    }
    &__upload {
        margin-top: 20px;
        display: flex;
        align-items: center;
        display: none;
        min-height: 75px;
        border-radius: 12px;
        border: 1px solid $gray3;
        padding: 16px;
        &-left {
            flex-grow: 1;
            max-width: calc(100% - 32px);
        }
        &-cancel {
            flex-shrink: 0;
            margin-left: auto;
            &:hover {
                path {
                    fill: $primary;
                }
            }
        }
        &-clip {
            flex-shrink: 0;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $gray2;
            border-radius: 50%;
            margin-right: 11px;
            &+.file-input__upload-left {
                max-width: calc(100% - 80px);
            }
        }
        &-title {
            font-size: 14px;
            line-height: 140%;
            white-space: nowrap;
            max-width: 100%;
            display: flex;
        }
        &-cut {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        &-progress {
            width: 100%;
            height: 4px;
            border-radius: 6px;
            margin-top: 4px;
            position: relative;
            background-color: $gray3;
            span {
                border-radius: 6px;
                background-color: $primaryHover;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                max-width: 100%;
            }
            &:before {
                content: attr(data-percent)'%';
                position: absolute;
                z-index: 1;
                right: 0;
                bottom: 100%;
                margin-bottom: 5px;
                font-size: 14px;
                line-height: 140%;
                color: $gray;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .check,
    .radio {
        label {
            font-size: 14px;
            padding-left: 26px;
            min-height: 18px;
            &::before {
                width: 18px;
                height: 18px;
            }
        }
    }
    .form-group {
        input,
        textarea {
            font-size: 14px;
        }
    }
    .file-input {
        label {
            font-size: 12px;
        }
    }
}

@media screen and (max-width: 576px) {
    .cartcalc {
        width: 98px;
        height: 34px;
        button {
            width: 18px;
            height: 18px;
            svg {
                width: 100%;
                height: auto;
            }
        }
        input {
            width: calc(100% - 40px);
        }
    }
}
$font1:'PT Root UI',
sans-serif;
$black: #000B15;
$primary: #015AAA;
$primaryHover:#0170D4;
$primaryActive:#014480;
$primary2:#56AFFE;
$gray: #666666;
$gray2:#F7F8FB;
$gray3:#DEE7F0;
$gray4:#9CA1AD;
$gray5:#D8D8D8;
$green:#69BE15;
$red:#FF0016;
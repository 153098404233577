@import "../vars.scss";
.error404 {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 100px 0;
    text-align: center;
    .container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
    &__inner {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__desc {
        line-height: 140%;
        margin-top: 20px;
    }
    &__link {
        padding: 0 19px;
        height: 46px;
        margin-top: 40px;
        font-size: 16px;
    }
}

.conf {
    padding: 60px 0 120px;
    &__inner {
        max-width: 900px;
    }
    &__title {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 576px) {
    .error404 {
        padding: 50px 0;
        &__link {
            margin-top: 20px;
        }
    }
}